import React from "react"


// import SEO from "../components/seo"

const NotFoundPage = () => (
<div>page not found</div>
)

export default NotFoundPage
